<template>
    <div>
        <div class="col-sm-12 col-md-9">
            <v-tabs v-model="shippingInstructionDocumentsManagerCurrentTab">
                <v-tab key="standard" style="text-transform: unset !important;">{{ $t('message.standardDocuments') }}</v-tab>
                <v-tab key="buyer" style="text-transform: unset !important;">{{ $t('message.countryCustomerSpecificDocuments') }}</v-tab>
                <v-tab key="others" style="text-transform: unset !important;">{{ $t('message.otherRequests') }}</v-tab>
                <v-tab key="presentation" style="text-transform: unset !important;">{{ $t('message.presentationDocuments') }}</v-tab>
                <v-spacer></v-spacer>
                <ExportTableJsonWithAdd
                        :export-conditions="exportConditions"
                        :export-data="exportData"
                        :export-fields="headers"
                        :export-source="'sidocs'"
                        :has-add-function="true"
                        class="mt-3"
                        @add-action="newShippingInstructionDocument"
                />
            </v-tabs>
            <div style="position: relative">
                <v-overlay
                        :value="loading.clauses"
                        absolute
                        opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-tabs-items v-model="shippingInstructionDocumentsManagerCurrentTab">
                    <v-tab-item>
                        <v-card flat>
                            <v-data-table
                                    :footer-props="{
                                        itemsPerPageOptions: [10,25,50,-1],
                                        showFirstLastPage: true
                                    }"
                                    :headers="headers"
                                    :hide-default-header="hideDefaultHeader"
                                    :hide-default-footer="hideDefaultFooter"
                                    :items="documents_standard"
                                    :items-per-page="-1"
                                    :options="tableOptions"
                                    calculate-widths
                                    class="mt-3 appic-table-light specification-table"
                                    dense
                                    id="standardTable"
                                    item-key="Ipa.id"
                            >
                                <template v-slot:item.Clause.addFilter="{ item }">
                                    <v-btn small @click="openAddClauseFilterDialog(item.Clause.id)">{{ $t('message.addFilter') }}</v-btn>
                                </template>
                                <template v-slot:item.Clause.filters="{ item }">
                                    <v-list dense>
                                        <v-list-item dense class="pl-0" v-for="filter in item.Clause.filters" :key="filter.SdaFilterValue.id">
                                            <v-list-item-title>
                                                {{ filter.SdaFilterValue.filter_name + ' --> ' + filter.SdaFilterValue.filter_value_title }}
                                            </v-list-item-title>
                                            <v-list-item-action>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-btn icon small v-on="{ ...tooltip }">
                                                            <v-icon small class="mr-1" color="red lighten-1" @click="deleteFilter(filter)">cancel</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('message.deleteFilter') }}</span>
                                                </v-tooltip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </template>
                                <template v-slot:item.Clause.id="{ item }">
                                    <div class="text-center">
                                        <v-menu>
                                            <template v-slot:activator="{ on: menu }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                            <v-icon>more_vert</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('message.moreActions') }}</span>
                                                </v-tooltip>
                                            </template>
                                            <v-list dense>
                                                <v-list-item class="font-sm" @click="openEditClauseDialog(item.Clause.id, item.Clause.title)">
                                                    <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDocument') }}
                                                </v-list-item>
                                                <v-list-item class="font-sm" @click="deleteClause(item.Clause.id, item.Clause.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteDocument') }}</v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-data-table
                                    :footer-props="{
                                        itemsPerPageOptions: [10,25,50,-1],
                                        showFirstLastPage: true
                                    }"
                                    :headers="headers"
                                    :hide-default-header="hideDefaultHeader"
                                    :hide-default-footer="hideDefaultFooter"
                                    :items="documents_countryBuyerSpecific"
                                    :items-per-page="-1"
                                    :options="tableOptions"
                                    calculate-widths
                                    class="mt-3 appic-table-light specification-table"
                                    dense
                                    id="countryBuyerSpecificTable"
                                    item-key="Ipa.id"
                            >
                                <template v-slot:item.Clause.addFilter="{ item }">
                                    <v-btn small @click="openAddClauseFilterDialog(item.Clause.id)">{{ $t('message.addFilter') }}</v-btn>
                                </template>
                                <template v-slot:item.Clause.filters="{ item }">
                                    <v-list dense>
                                        <v-list-item dense class="pl-0" v-for="filter in item.Clause.filters" :key="filter.SdaFilterValue.id">
                                            <v-list-item-title>
                                                {{ filter.SdaFilterValue.filter_name + ' --> ' + filter.SdaFilterValue.filter_value_title }}
                                            </v-list-item-title>
                                            <v-list-item-action>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-btn icon small v-on="{ ...tooltip }">
                                                            <v-icon small class="mr-1" color="red lighten-1" @click="deleteFilter(filter)">cancel</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('message.deleteFilter') }}</span>
                                                </v-tooltip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </template>
                                <template v-slot:item.Clause.id="{ item }">
                                    <div class="text-center">
                                        <v-menu>
                                            <template v-slot:activator="{ on: menu }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                            <v-icon>more_vert</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('message.moreActions') }}</span>
                                                </v-tooltip>
                                            </template>
                                            <v-list dense>
                                                <v-list-item class="font-sm" @click="openEditClauseDialog(item.Clause.id, item.Clause.title)">
                                                    <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDocument') }}
                                                </v-list-item>
                                                <v-list-item class="font-sm" @click="deleteClause(item.Clause.id, item.Clause.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteDocument') }}</v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-data-table
                                    :footer-props="{
                                        itemsPerPageOptions: [10,25,50,-1],
                                        showFirstLastPage: true
                                    }"
                                    :headers="headers"
                                    :hide-default-header="hideDefaultHeader"
                                    :hide-default-footer="hideDefaultFooter"
                                    :items="documents_otherRequests"
                                    :items-per-page="-1"
                                    :options="tableOptions"
                                    calculate-widths
                                    class="mt-3 appic-table-light specification-table"
                                    dense
                                    id="otherRequestsTable"
                                    item-key="Ipa.id"
                            >
                                <template v-slot:item.Clause.addFilter="{ item }">
                                    <v-btn small @click="openAddClauseFilterDialog(item.Clause.id)">{{ $t('message.addFilter') }}</v-btn>
                                </template>
                                <template v-slot:item.Clause.filters="{ item }">
                                    <v-list dense>
                                        <v-list-item dense class="pl-0" v-for="filter in item.Clause.filters" :key="filter.SdaFilterValue.id">
                                            <v-list-item-title>
                                                {{ filter.SdaFilterValue.filter_name + ' --> ' + filter.SdaFilterValue.filter_value_title }}
                                            </v-list-item-title>
                                            <v-list-item-action>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-btn icon small v-on="{ ...tooltip }">
                                                            <v-icon small class="mr-1" color="red lighten-1" @click="deleteFilter(filter)">cancel</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('message.deleteFilter') }}</span>
                                                </v-tooltip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </template>
                                <template v-slot:item.Clause.id="{ item }">
                                    <div class="text-center">
                                        <v-menu>
                                            <template v-slot:activator="{ on: menu }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                            <v-icon>more_vert</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('message.moreActions') }}</span>
                                                </v-tooltip>
                                            </template>
                                            <v-list dense>
                                                <v-list-item class="font-sm" @click="openEditClauseDialog(item.Clause.id, item.Clause.title)">
                                                    <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDocument') }}
                                                </v-list-item>
                                                <v-list-item class="font-sm" @click="deleteClause(item.Clause.id, item.Clause.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteDocument') }}</v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-data-table
                                    :footer-props="{
                                        itemsPerPageOptions: [10,25,50,-1],
                                        showFirstLastPage: true
                                    }"
                                    :headers="headers"
                                    :hide-default-header="hideDefaultHeader"
                                    :hide-default-footer="hideDefaultFooter"
                                    :items="documents_presentation"
                                    :items-per-page="-1"
                                    :options="tableOptions"
                                    calculate-widths
                                    class="mt-3 appic-table-light specification-table"
                                    dense
                                    id="presentationTable"
                                    item-key="Ipa.id"
                            >
                                <template v-slot:item.Clause.addFilter="{ item }">
                                    <v-btn small @click="openAddClauseFilterDialog(item.Clause.id)">{{ $t('message.addFilter') }}</v-btn>
                                </template>
                                <template v-slot:item.Clause.filters="{ item }">
                                    <v-list dense>
                                        <v-list-item dense class="pl-0" v-for="filter in item.Clause.filters" :key="filter.SdaFilterValue.id">
                                            <v-list-item-title>
                                                {{ filter.SdaFilterValue.filter_name + ' --> ' + filter.SdaFilterValue.filter_value_title }}
                                            </v-list-item-title>
                                            <v-list-item-action>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-btn icon small v-on="{ ...tooltip }">
                                                            <v-icon small class="mr-1" color="red lighten-1" @click="deleteFilter(filter)">cancel</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('message.deleteFilter') }}</span>
                                                </v-tooltip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </template>
                                <template v-slot:item.Clause.id="{ item }">
                                    <div class="text-center">
                                        <v-menu>
                                            <template v-slot:activator="{ on: menu }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                            <v-icon>more_vert</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('message.moreActions') }}</span>
                                                </v-tooltip>
                                            </template>
                                            <v-list dense>
                                                <v-list-item class="font-sm" @click="openEditClauseDialog(item.Clause.id, item.Clause.title)">
                                                    <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDocument') }}
                                                </v-list-item>
                                                <v-list-item class="font-sm" @click="deleteClause(item.Clause.id, item.Clause.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteDocument') }}</v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
        <AddShippingInstructionClause
                :document-id="selectedDocumentId"
                :document-title="selectedDocumentTitle"
                :dialog.sync="editDialog"
                :page-key="pageKey"
                :update-mode="updateMode"
                @dialog-closed="editDialogClosed"
                @update-done="documentUpdated"
                @create-done="documentCreated"
        />
        <AddClauseFilter
                :clause-id="selectedDocumentId"
                :dialog.sync="addDialog"
                :page-key="pageKey2"
                @dialog-closed="addFilterDialogClosed"
                @create-done="filterCreated"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";

const AddShippingInstructionClause = () => import("Components/Appic/AddShippingInstructionClause");
const AddClauseFilter = () => import("Components/Appic/AddClauseFilter");

const ExportTableJsonWithAdd = () => import("./ExportTableJsonWithAdd");

export default {
    name: "SiDocumentManager",
    components: {AddShippingInstructionClause, ExportTableJsonWithAdd, AddClauseFilter},
    data() {
        return {
            addDialog: null,
            editDialog: null,
            exportConditions: {},
            exportData: [],
            hideDefaultHeader: false,
            hideDefaultFooter: false,
            loading: {
                clauses: false
            },
            selectedDocumentId: null,
            selectedDocumentTitle: null,
            tableOptions: {},
            updateMode: false
        }
    },
    computed: {
        ...mapFields('sdadocument',{
            documents_standard: 'allDocuments.standard',
            documents_countryBuyerSpecific: 'allDocuments.countryBuyerSpecific',
            documents_otherRequests: 'allDocuments.standard',
            documents_presentation: 'allDocuments.presentation'
        }),
        ...mapFields('runtime',{
            shippingInstructionDocumentsManagerCurrentTab: 'shippingInstructionDocumentsManagerCurrentTab'
        }),
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Clause.id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.documentClause'),
                    value: 'Clause.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.addFilter'),
                    value: 'Clause.addFilter',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.filters'),
                    value: 'Clause.filters',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    sortable: true
                },
            ]
        },
        pageKey() {
            return Math.floor(Math.random() * 100)
        },
        pageKey2() {
            return Math.floor(Math.random() * 100)
        }
    },
    methods: {
        ...mapActions('sdadocument',{
            deleteSdaDocumentById: 'deleteSdaDocumentById',
            deleteSdaDocumentFilterById: 'deleteSdaDocumentFilterById',
            getSdaDocuments: 'getSdaDocuments',
            resetState: 'resetState'
        }),
        addFilterDialogClosed () {
            this.addDialog = false;
            this.$emit('add-dialog-closed')
        },
        async deleteClause(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteDocument') + ': ' +  title, this.$t('message.confirmations.continueClauseCancelAction'), {color: 'orange'})){
                this.deleteSdaDocumentById(val)
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.documentClauseDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetState()
                            this.getSdaDocuments()
                        } else {
                            this.$toast.error(this.$t('message.errors.documentClauseNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.documentClauseNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        async deleteFilter(filter) {
            let title = filter.SdaFilterValue.filter_name + ' --> ' + filter.SdaFilterValue.filter_value_title
            let val = filter.SdaFilterValue.id
            if(await this.$root.$confirm(this.$t('message.deleteFilter') + ': ' +  title, this.$t('message.confirmations.continueFilterCancelAction'), {color: 'orange'})){
                this.deleteSdaDocumentFilterById(val)
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.filterDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetState()
                            this.getSdaDocuments()
                        } else {
                            this.$toast.error(this.$t('message.errors.filterNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.filterNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        documentCreated () {
            this.editDialog = false;
            this.resetState()
            this.getSdaDocuments()
            this.$emit('reload')
        },
        documentUpdated () {
            this.editDialog = false;
            this.resetState()
            this.getSdaDocuments()
            this.$emit('reload')
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            } else {
                this.updateMode = false
                this.selectedDocumentId = null
                this.selectedDocumentTitle = null
            }
        },
        filterCreated() {
            this.addDialog = false;
            this.resetState()
            this.getSdaDocuments()
            this.$emit('reload')
        },
        openEditClauseDialog (clauseId, clauseTitle) {
            this.selectedDocumentId = clauseId
            this.selectedDocumentTitle = clauseTitle
            this.updateMode = true
            this.editDialog = true
        },
        openAddClauseFilterDialog (clauseId) {
            this.selectedDocumentId = clauseId
            this.addDialog = true
        },
        newShippingInstructionDocument() {
            this.updateMode = false
            this.editDialog = true
        }
    },
    created() {
        this.loading.clauses = true
        this.resetState()
        this.getSdaDocuments()
            .then(() => {
                this.loading.clauses = false
            })
            .catch(() => {
                this.loading.clauses = false
            })
    },
}
</script>

<style scoped>
 .v-list-item--dense, .v-list--dense .v-list-item {
     min-height: 30px !important;
 }
 .v-list-item--dense, .v-list--dense .v-list-item__action {
     margin-top: 3px !important;
     margin-bottom: 3px !important;
 }
</style>