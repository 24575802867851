import { render, staticRenderFns } from "./SiDocumentManager.vue?vue&type=template&id=10cf318c&scoped=true&"
import script from "./SiDocumentManager.vue?vue&type=script&lang=js&"
export * from "./SiDocumentManager.vue?vue&type=script&lang=js&"
import style0 from "./SiDocumentManager.vue?vue&type=style&index=0&id=10cf318c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10cf318c",
  null
  
)

export default component.exports